import React, { useEffect, useState } from "react";
import "./PlayGame.css";
import "../../css/commanSwiperPaginationStyle.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import assets
import sattaMatkaImage from "../../Assets/playGames/sattaMatka.png";
import liveCasinoImage from "../../Assets/playGames/liveCasino.png";
import slotsImage from "../../Assets/playGames/slots.png";
import sportExchangeImage from "../../Assets/playGames/sportExchange.png";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";
import { useNavigate } from "react-router-dom";
import SecondaryCarousel from "../Carousel/SecondaryCarousel";
import ComponentHeading from "../ComponentHeading/ComponentHeading";
import { SessionHelper } from "../../Utilities/SessionHelper";
import { Various_APIs } from "../../API/Various_APIs";
import { Providers_Enums } from "../../Configuration/Enums/Providers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";

const playGames = {
    satta: sattaMatkaImage,
    "casino?data=slots": slotsImage,
    exchange: sportExchangeImage,
    "casino-category": liveCasinoImage,
    falcon_exchange: sportExchangeImage,
    "casino-qtech": liveCasinoImage,
};

const PlayGame = ({ data, navigateFun, heading }) => {
    // const [playGames, setPlayGames] = useState([]);
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const navigate = useNavigate();

    const getAppConfig = async () => {
        const appConfig = await Various_APIs.Get_App_Config();
        SessionHelper.setActiveExchangeProvider(
            appConfig?.data?.data?.payload?.activeCasinoProvider ?? Providers_Enums.CAPITAL_QTECH
        );
    };

    useEffect(() => {
        getAppConfig();
    }, []);

    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    const handleNavigateExchange = () => {};

    const handleNavigateFunc = (item) => {
        if (item?.category === "sattamatka") {
            navigate(`/${item?.iframeUrl}`);
            navigate(`/satta`);
            return;
        }
        if (item?.category === "exchange") {
            navigate("/exchange-select");
            return;
        }
        if (activeCasinoProvider === "QTECH" && item.iframeUrl === "casino-qtech") {
            navigateFun(item.iframeUrl);
        } else if (activeCasinoProvider === "WACS" && item.iframeUrl === "casino-qtech") {
            navigateFun("casino-category");
        } else if (
            activeCasinoProvider === "WACS" ||
            (activeCasinoProvider === "QTECH" && item.iframeUrl === "casino?data=slots")
        ) {
            navigate("/casino-category", { state: { Category: "SLOT" } });
        } else {
            navigateFun(item.iframeUrl);
        }
    };

    // useEffect(() => {
    //     setPlayGames([sattaMatkaImage, slotsImage, liveCasinoImage, sportExchangeImage]);
    // }, []);

    return (
        <>
            <div className="play-game-section">
                <GamesHeaderMarker showBtn={false} markerText={"Play Game"} />
                <div className="play-games-container_">
                    <Swiper
                        slidesPerView={isTabOrMobile ? 3 : 4}
                        spaceBetween={0} // Adjust this value to control spacing between slides
                        slidesPerGroup={isTabOrMobile ? 1 : 4}
                        freeMode={true}
                        loop={false}
                        autoplay={{ delay: 2500 }}
                        navigation={false}
                        id="comman-swiper-pagination"
                        pagination={{ clickable: true }}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                        style={{ padding: 0 }} // Ensure no default padding is applied
                    >
                        <SwiperSlide style={{ backgroundColor: "transparent" }}>
                            <div className="play-game-card">
                                <LazyLoadImage
                                    className="play-game-img"
                                    src={playGames.exchange}
                                    alt="Fairbets"
                                    onClick={() => navigate("/exchange-select")}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide style={{ backgroundColor: "transparent" }}>
                            <div className="play-game-card">
                                <LazyLoadImage
                                    className="play-game-img"
                                    src={playGames.satta}
                                    alt="Fairbets"
                                    onClick={() => navigate(`/satta`)}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide style={{ backgroundColor: "transparent" }}>
                            <div className="play-game-card">
                                <LazyLoadImage
                                    className="play-game-img"
                                    src={playGames["casino-category"]}
                                    alt="Fairbets"
                                    onClick={() => navigateFun("casino-select")}
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide style={{ backgroundColor: "transparent" }}>
                            <div className="play-game-card">
                                <LazyLoadImage
                                    className="play-game-img"
                                    src={playGames["casino?data=slots"]}
                                    alt="Fairbets"
                                    onClick={() =>
                                        navigate("/casino-category", {
                                            state: { Category: "SLOT" },
                                        })
                                    }
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default PlayGame;
