import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { Game_Context } from "../../Contexts/Games";
import { User_Context } from "../../Contexts/User";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import DepositImage from "../../icons/deposit.png";
import WithdrawalsIcons from "../../icons/withdrawals.png";
import KycIcons from "../../icons/kyc.png";
import BetHistoryIcon from "../../icons/bet-history.png";
import AccountStatementIcons from "../../icons/account-statement.png";
import ChangePasswordIcons from "../../icons/change-password.png";
import whatsappIcon from "../../icons/Whatsapp.png";
import CancelWithdrawalsIcon from "../../icons/CancelWithdrwal.png";
import TelegramIcon from "../../icons/tele.png";
import LiveChatIcon from "../../icons/Live-chat.png";
import ReferalCodeIcon from "../../icons/refral.png";
import BonusHistoryIcon from "../../icons/Bonus.png";
import SattaMatkaIcon from "../../icons/chip.png";
import ExchangeIcon from "../../icons/cricket.png";
import CasinoIcon from "../../icons/casino.png";
import QtechIcon from "../../icons/kasq.png";
import { decryptData } from "../../Utilities/DecryptData";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { getRandomElement } from "../../Utilities/getRandomElement";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../../Configuration/Firebase";
import Cookies from "universal-cookie";
import { Translation_Table } from "../../Configuration/Translation";
import Grid from "@mui/material/Grid";
import {
    Casino_Game_Categories_Enums,
    newCasinoCategoriesMapping,
} from "../../Configuration/Enums/Casino_Game_Categories";
import { Language_Context } from "../../Contexts/Language";

// Components
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";
import Kyc from "../../components/Modals/Kyc";
import Withdraw from "../../components/Modals/withdraw";
import ProfileOptionCard from "../../components/ProfileOptionCard/ProfileOptionCard";
import ProfileFooter from "../../components/ProfileFooter/ProfileFooter";
import ProfileTabs from "./ProfileTab";
import MobileFooter from "../../components/MobileFooter/MobileFooter";

// Styles
import "../../css/profile.css";
import CancelWithdrawal from "../../components/Modals/cancelWithdrawal";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className="tabs-main-container"
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ProfileTab() {
    const [value, setValue] = React.useState(0);
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
    const [showModal, setShowModal] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [kycModal, setKycModal] = useState(false);
    const [userData, setUserData] = useState({});
    const [modalChangePassword, setModalChangePassword] = useState(false);
    const cookies = new Cookies();
    const { User, Set_User } = useContext(User_Context);
    const { Language, Set_Language } = useContext(Language_Context);
    const navigate = useNavigate();
    const navigateToHome = (e) => {
        e.preventDefault();
        navigate("/");
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const app = initializeApp(firebaseConfig);

    const [messaging, setMessaging] = useState(null);

    useEffect(() => {
        if (app) {
            isSupported()
                .then((res) => {
                    setMessaging(getMessaging(app));
                })
                .catch((error) => {
                    console.log("error ===========>", error);
                });
        }
    }, [app]);

    // const callUserData = (e)=>{
    // 	e.preventDefault()
    // 	if(!User.data.accountNumber){
    // 	User_APIs.Get_User ({_id:User.data._id}, User.token).then((response) => {
    // 			response.data.data =  (response.data.data)
    // 			setUserData (response.data.data.payload);
    // 			if(User.data.accountNumber || userData.accountNumber){
    // 				setKycModal(false);
    // 			    setShowModal(true);
    // 			}
    // 		}).catch((error) => {
    // 		});
    // 	}else{
    // 		setKycModal(false);
    // 			    setShowModal(true);
    // 	}
    // }

    const handleLiveChatClick = () => {
        // const userAgent = window?.navigator?.userAgent ?? '';
        // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
        // 	window.open(whatsapp, '_blank', 'noopener,noreferrer')
        // 	return;
        // }
        onNavigateToLiveChat();
    };

    const onNavigateToLiveChat = async () => {
        try {
            const response = await User_APIs.Get_Support_Executive_List({}, User.token);
            const responseData = response.data ? response.data : {};
            const { success, data } = responseData;
            if (success) {
                const chatSupportData = decryptData(data);
                const dp_id = getRandomElement(chatSupportData.payload);
                // getSupportFirebaseToken(dp_id)
                requestBrowserPermissions(dp_id);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
    };

    const requestBrowserPermissions = (dp_id) => {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                if (messaging) {
                    getToken(messaging, {
                        vapidKey:
                            "BNw5X5Cq68O_tEY_t8d5x2t1dK6dQg91sqKYPOc364yPlHh4aIV6WLPIhrF-RmhLCnbBW_qyUYtqnAYKedrsJkQ",
                    })
                        .then((currentToken) => {
                            if (currentToken) {
                                registerUserForChatApplication(currentToken, dp_id);
                            } else {
                                // Show permission request UI
                                // console.log('No registration token available. Request permission to generate one.');
                                // ...
                            }
                        })
                        .catch((err) => {
                            // console.log('An error occurred while retrieving token. ', err);
                            // ...
                        });
                }
            } else if (permission === "denied") {
                toast.info("Please enable notifications in your Browser settings to Proceed");
            }
        });
    };

    const registerUserForChatApplication = async (fcmToken, dp_id) => {
        try {
            let payload = {
                _id: User.data._id,
                supportExecutiveDp_Id: dp_id,
                mobile: User.data.mobile,
                name: User.data.name,
                fireBaseToken: fcmToken,
            };

            const response = await User_APIs.Register_User_For_Chat(payload, User.token);

            const responseData = response.data ? response.data : {};
            const { success, data } = responseData;
            if (success) {
                const decryptedData = decryptData(data);
                const User_Copy = { ...User };
                User_Copy.data.fireBaseToken = decryptedData.payload.fireBaseToken;
                User_Copy.data.supportExecutiveDp_Id = decryptedData.payload.supportExecutiveDp_Id;
                Set_User(User_Copy);
                navigate("/live-chat");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        // setLoading(false)
    };

    const navigateToBetHistory = () => {
        navigate("/bet-history");
    };
    const navigateToBonusWalletHistory = () => {
        navigate("/bonus-wallet-history");
    };
    const notify = () => {
        toast.info("KYC has been already completed");
    };
    const notifyKyc = () => {
        toast.error("Please complete the KYC process first");
    };

    const navigateToAccountStatement = () => {
        navigate("/account-statement");
    };
    const navigateToFeedback = () => {
        navigate("/feedback");
    };

    const navigateToDeposit = () => {
        if (!User.data.kyc) {
            toast.error("please complete your kyc first");
        } else {
            navigate("/deposit?data=deposit");
        }
    };
    const navigateToWithdrawalRequest = () => {
        navigate("/withdrawal-Request");
    };

    const copyShare = () => {
        if (isTabOrMobile) {
            window.navigator
                .share({
                    text: User.data?.referralCodeUser,
                    url: "https://fairbets.co/",
                })
                .then(() => toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`))
                .catch((err) => toast.error(`Unable to copy`));
        } else {
            window.navigator.clipboard.writeText(User.data?.referralCodeUser);
            toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`);
        }
    };

    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const { whatsapp, telegram } = gameState;

    const handleKycClick = () => {
        if (!User?.data?.kyc) {
            setKycModal(true);
            return;
        }
        toast.error("KYC already completed");
        setShowModal(true);
    };

    const handleHelix = () => {
        window.location.href = `https://plutusgaming.casino/validate/${User.data._id}/P1002/helix`;
    };

    const handleCatfish = () => {
        window.location.href = `https://plutusgaming.casino/validate/${User.data._id}/P1002/catFish`;
    };

    const handleMines = () => {
        window.location.href = `https://plutusgaming.casino/validate/${User.data._id}/P1002/mines`;
    };

    return (
        <div>
            <Box className="tabs-box-container pb-4">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="tabs">
                        <Tab
                            label="Account"
                            {...a11yProps(0)}
                            className={`profile-tabs ${value === 0 ? "selected-tab" : ""}`}
                        />
                        <Tab
                            label="Game"
                            {...a11yProps(1)}
                            className={`profile-tabs ${value === 1 ? "selected-tab" : ""}`}
                        />
                        <Tab
                            label="Help"
                            {...a11yProps(2)}
                            className={`profile-tabs ${value === 2 ? "selected-tab" : ""}`}
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <div className="tabs-container">
                        <div className="tabs-sub-container">
                            <Image
                                src={DepositImage}
                                alt="DepositImage"
                                title="Deposit"
                                className="tab-img"
                                onClick={navigateToDeposit}
                            />
                            <span className="tabs-content">Deposit</span>
                        </div>

                        <div className="tabs-sub-container">
                            <Image
                                src={WithdrawalsIcons}
                                alt="WithdrawalsIcons"
                                title="WithdrawalsIcons"
                                className="tab-img"
                                onClick={(e) => {
                                    if (User.data.kyc) {
                                        setKycModal(false);
                                        setShowModal(true);
                                    } else {
                                        setKycModal(true);
                                        notifyKyc();
                                    }
                                }}
                            />
                            <span className="tabs-content">Withdrawals</span>
                        </div>
                        <div className="tabs-sub-container">
                            <Image
                                src={CancelWithdrawalsIcon}
                                alt="CancelWithdrawals"
                                title="CancelWithdrawals"
                                className="tab-img"
                                onClick={navigateToWithdrawalRequest}
                            />
                            <span className="tabs-content">Cancel Withdrawal</span>
                        </div>
                    </div>

                    <div className="tabs-container">
                        <div className="tabs-sub-container" onClick={handleKycClick}>
                            <Image
                                src={KycIcons}
                                alt="KycIcons"
                                title="KycIcons"
                                className="tab-img"
                            />
                            <span className="tabs-content">KYC</span>
                        </div>

                        <div className="tabs-sub-container">
                            <Image
                                src={BetHistoryIcon}
                                alt="BetHistoryIcon"
                                title="BetHistoryIcon"
                                className="tab-img"
                                onClick={navigateToBetHistory}
                            />
                            <span className="tabs-content">Bet History</span>
                        </div>

                        <div className="tabs-sub-container" onClick={navigateToBonusWalletHistory}>
                            <Image
                                src={BonusHistoryIcon}
                                alt="BonusHistoryIcon"
                                title="BonusHistory"
                                className="tab-img"
                            />
                            <span className="tabs-content">{`Bonus Wallet History`}</span>
                        </div>
                    </div>

                    <div className="tabs-container">
                        <div className="tabs-sub-container">
                            <Image
                                src={AccountStatementIcons}
                                alt="AccountStatementIcons"
                                title="AccountStatementIcons"
                                className="tab-img"
                                onClick={navigateToAccountStatement}
                            />
                            <span className="tabs-content">Account Statement</span>
                        </div>

                        <div className="tabs-sub-container">
                            <Image
                                src={ChangePasswordIcons}
                                alt="ChangePasswordIcons"
                                title="ChangePasswordIcons"
                                className="tab-img"
                                onClick={() => {
                                    setModalChangePassword(true);
                                }}
                            />
                            <span className="tabs-content">Change Password</span>
                        </div>
                        {/* <div className="tabs-sub-container">
                            <Image
                                src={ChangePasswordIcons}
                                alt="ChangePasswordIcons"
                                title="ChangePasswordIcons"
                                className="tab-img"
                                onClick={handleHelix}
                            />
                            <span className="tabs-content">Helix Game</span>
                        </div>
                        <div className="tabs-sub-container">
                            <Image
                                src={ChangePasswordIcons}
                                alt="ChangePasswordIcons"
                                title="ChangePasswordIcons"
                                className="tab-img"
                                onClick={handleCatfish}
                            />
                            <span className="tabs-content">Catfish Game</span>
                        </div>
                        <div className="tabs-sub-container">
                            <Image
                                src={ChangePasswordIcons}
                                alt="ChangePasswordIcons"
                                title="ChangePasswordIcons"
                                className="tab-img"
                                onClick={handleMines}
                            />
                            <span className="tabs-content">Mines Game</span>
                        </div> */}
                    </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <div className="tabs-container">
                        <div className="tabs-sub-container">
                            <Image
                                src={SattaMatkaIcon}
                                alt="SattaMatka"
                                title="SattaMatka"
                                className="tab-img"
                                onClick={() => navigate("/satta")}
                            />
                            <span className="tabs-content">
                                {Translation_Table.Satta_Matka[Language]}
                            </span>
                        </div>

                        <div className="tabs-sub-container">
                            <Image
                                src={ExchangeIcon}
                                alt="Exchange"
                                title="Exchange"
                                className="tab-img"
                                onClick={() => navigate("/exchange")}
                            />
                            <span className="tabs-content">
                                {Translation_Table.exchange[Language]}
                            </span>
                        </div>

                        <div className="tabs-sub-container">
                            <Image
                                src={CasinoIcon}
                                alt="Casino"
                                title="Casino"
                                className="tab-img"
                                onClick={() => {
                                    navigate("/casino-category", {
                                        state: {
                                            Category:
                                                newCasinoCategoriesMapping[
                                                    Casino_Game_Categories_Enums.Live_Casino
                                                ],
                                        },
                                    });
                                }}
                            />
                            <span className="tabs-content">
                                {Translation_Table.casino[Language]}
                            </span>
                        </div>
                    </div>

                    <div className="tabs-container">
                        <div className="tabs-sub-container">
                            <Image
                                src={QtechIcon}
                                alt="Qtech"
                                title="Qtech"
                                className="tab-img"
                                onClick={() => {
                                    navigate("/casino-qtech?data=live-casino", {
                                        state: {
                                            Category: Casino_Game_Categories_Enums.Live_Casino,
                                        },
                                    });
                                }}
                            />
                            <span className="tabs-content">{"QTech"}</span>
                        </div>
                    </div>
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                    <div className="tabs-container">
                        <div
                            className="tabs-sub-container"
                            onClick={() => window.open(whatsapp, "_blank", "noopener,noreferrer")}
                        >
                            <Image
                                src={whatsappIcon}
                                alt="whatsappIcon"
                                title="whatsapp"
                                className="tab-img"
                            />
                            <span className="tabs-content">
                                {Translation_Table.whatsapp[Language]}
                            </span>
                        </div>

                        <div
                            className="tabs-sub-container"
                            onClick={() => window.open(telegram, "_blank", "noopener,noreferrer")}
                        >
                            <Image
                                src={TelegramIcon}
                                alt="TelegramIcon"
                                title="Telegram"
                                className="tab-img"
                            />
                            <span className="tabs-content">Telegram</span>
                        </div>

                        <div className="tabs-sub-container" onClick={handleLiveChatClick}>
                            <Image
                                src={LiveChatIcon}
                                alt="LiveChatIcon"
                                title="LiveChat"
                                className="tab-img"
                            />
                            <span className="tabs-content">Live Chat</span>
                        </div>
                    </div>

                    <div className="tabs-container">
                        <div className="tabs-sub-container" onClick={() => copyShare()}>
                            <Image
                                src={ReferalCodeIcon}
                                alt="ReferalCodeIcon"
                                title="ReferalCode"
                                className="tab-img"
                            />
                            <span className="tabs-content">{`Referral Code: ${User.data.referralCodeUser}`}</span>
                        </div>
                    </div>
                </CustomTabPanel>
            </Box>

            <ChangePasswordModal
                show={modalChangePassword}
                onHide={() => {
                    setModalChangePassword(false);
                }}
            />

            <Withdraw
                show={showModal}
                onHide={() => {
                    setShowModal(false);
                }}
            />
            <CancelWithdrawal
                show={cancelModal}
                onHide={() => {
                    setCancelModal(false);
                }}
            />
            <Kyc
                closekyc={() => {
                    setKycModal(false);
                }}
                show={kycModal}
                onHide={() => {
                    setKycModal(false);
                }}
            />
        </div>
    );
}
