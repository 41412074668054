import React, { useContext, useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Casino_APIs } from "../../API/Casino_APIs";
import { User_Context } from "../../Contexts/User";
import { decryptData } from "../../Utilities/DecryptData";
import Logout_User from "../../Utilities/Logout";
import { useNavigate, useLocation } from "react-router-dom";
import usePageBottom from "../../Hooks/usePageBottom";
import Cookies from "universal-cookie";
import { encryptData } from "../../Utilities/EncryptData";
import { toast } from "react-toastify";
import { Game_Context } from "../../Contexts/Games";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";

// Components
import Loader from "../../components/Loader/Loader";
import Mini_Loader from "../../components/Mini_Loader/Mini_Loader";
import TopGamesSlider from "../../components/TopGamesSlider/TopGamesSlider";
import TopProvidersSlider from "../../components/TopProvidersSlider/TopProvidersSlider";

// Assets
import BgImg from "../../Assets/Images/image1.jpg";
import Thumbnail_Fallback_Image from "../../Assets/Images/Thumbnail_Fallback_Image.webp";
import { ReactComponent as Name } from "../../Assets/QtechCategoryIcons/a-z.svg";
import SlotsIcon from "../../Assets/QtechCategoryIcons/slot-machine.png";
import VirtualSportIcon from "../../Assets/QtechCategoryIcons/roulette1.png";
import VideoPokerIcon from "../../Assets/QtechCategoryIcons/poker-cards.png";
import LiveCasinoIcon from "../../Assets/QtechCategoryIcons/dice1.png";
import InstantWinIcon from "../../Assets/QtechCategoryIcons/black-head.png";
import ShootingIcon from "../../Assets/QtechCategoryIcons/shooting.png";
import VideoVideoPokerIcon from "../../Assets/QtechCategoryIcons/videopoker.png";
import TokenIcon from "../../Assets/QtechCategoryIcons/spade-token.png";
import LotteryIcon from "../../Assets/QtechCategoryIcons/lottery-small.png";
import Roulette from "../../Assets/QtechCategoryIcons/roulette1.png";
import Livecasino from "../../Assets/QtechCategoryIcons/livecasino.png";
import Blackjack from "../../Assets/QtechCategoryIcons/blackjack.png";
import Baccarat from "../../Assets/QtechCategoryIcons/Baccarat.png";
import Poker from "../../Assets/QtechCategoryIcons/Poker.png";
import Ezugi from "../../Assets/QtechCategoryIcons/Ezugi.png";
import Evolution from "../../Assets/QtechCategoryIcons/Evolution.png";
import Virtuals from "../../Assets/QtechCategoryIcons/Virtuals.png";
import Andarbahar from "../../Assets/QtechCategoryIcons/Andarbahar.png";

// Styles
import "./Casino.css";
import { SessionHelper } from "../../Utilities/SessionHelper";
import { Various_APIs } from "../../API/Various_APIs";
import { Providers_Enums } from "../../Configuration/Enums/Providers";

const itemsPerPage = 24;

function CasinoCategories() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const location = useLocation();
    const isBottom = usePageBottom();
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
    const [gameList, setGameList] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const { User, Set_User } = useContext(User_Context);
    const [loading, setLoading] = useState(false);
    const [nextListLoading, setNextListLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [categoriesId, setCategoriesId] = useState("6548e840a95f5dcd3f5b7195");

    const casinoCategory = new URLSearchParams(location.search).get("casinoCategory");

    const [selectedCategory, setSelctedCategory] = useState(
        location?.state?.Category ?? casinoCategory ?? "LIVECASINO"
    );
    const [activeCasinoProvider, setActiveCasinoProvider] = useState(
        SessionHelper.getActiveExchangeProvider()
    );

    const userToken = new URLSearchParams(location.search).get("usertoken");
    const userid = new URLSearchParams(location.search).get("userid");
    const username = new URLSearchParams(location.search).get("name");
    const usermobile = new URLSearchParams(location.search).get("mobile");
    const useremail = new URLSearchParams(location.search).get("email");
    const usercity = new URLSearchParams(location.search).get("city");
    const userstate = new URLSearchParams(location.search).get("state");
    const userBalance = new URLSearchParams(location.search).get("userBalance");
    const userExposer = new URLSearchParams(location.search).get("userExposer");
    const userBonusWalletBalance = new URLSearchParams(location.search).get(
        "userBonusWalletBalance"
    );

    const firstDepositDone = new URLSearchParams(location.search).get("firstDepositDone");
    const referredUserId = new URLSearchParams(location.search).get("referredUserId");
    const referredCode = new URLSearchParams(location.search).get("referredCode");

    // useEffect(() => {
    //     if(categories?.length){
    //         setCategoriesId(categories?.[0]?._id);
    //     }
    // }, [categories])

    // useEffect(() => {
    //     setSelctedCategory(location?.state?.Category ?? casinoCategory ?? "LIVECASINO");
    // }, [location?.state?.Category, casinoCategory]);

    const getAppConfig = async () => {
        const appConfig = await Various_APIs.Get_App_Config();
        const activeProvider =
            appConfig?.data?.data?.payload?.activeCasinoProvider ?? Providers_Enums.CAPITAL_QTECH;
        SessionHelper.setActiveExchangeProvider(activeProvider);
        setActiveCasinoProvider(activeProvider);
    };

    const updateUserContext = () => {
        if (
            userToken &&
            userid &&
            username &&
            usermobile &&
            useremail &&
            userBalance &&
            userstate &&
            usercity &&
            userExposer &&
            userBonusWalletBalance &&
            firstDepositDone &&
            referredCode &&
            referredUserId
        ) {
            let userObj = {
                _id: userid,
                subAdminId: "",
                name: username,
                kyc: true,
                mobile: usermobile,
                email: useremail,
                state: userstate,
                city: usercity,
                balance: userBalance,
                exposer: userExposer,
                deviceType: "M",
                bonusWalletBalance: userBonusWalletBalance,
                firstDepositDone: firstDepositDone,
                referredUserId: referredUserId,
                referredCode: referredCode,
                createdOn: "",
                updatedOn: "",
                aadhaarNumber: "",
                accountNumber: "",
                bankName: "",
                ifsc: "",
                fireBaseToken: "",
                supportExecutiveDp_Id: "",
            };

            cookies.set("userData", encryptData(userObj));
            cookies.set("jwt-authorization", encryptData(userToken));
            Set_User({ token: userToken, data: userObj });
            getCategories();
        } else {
            toast.error("Error Occured");
            setLoading(false);
        }
    };

    const getCategories = async () => {
        setLoading(true);
        try {
            const response = await Casino_APIs.Get_Qtech_Casino_Categories(
                {},
                userToken || User.token
            );
            const { data, success } = response.data ? response.data : {};
            if (success) {
                const finalData = decryptData(data);
                setCategories(finalData.payload);
                getGames(1, selectedCategory ?? finalData?.payload[0].category);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setCategories([]);
            if (error.response && error.response.status === 401) {
                if (
                    location?.pathname === "/casino-category-mobile" &&
                    window?.ReactNativeWebView
                ) {
                    toast.error("Please login again");
                    window.ReactNativeWebView.postMessage(JSON.stringify({ error: 401 }));
                } else {
                    Logout_User(Set_User);
                    navigate("/");
                }
            }
        }
    };

    const getGames = async (page, category) => {
        setLoading(true);
        try {
            let payload =
                activeCasinoProvider === "QTECH"
                    ? {
                          pageNo: page,
                          itemPerPage: itemsPerPage,
                          category: category,
                      }
                    : {
                          Category_ID: category,
                          Items_per_Page: itemsPerPage,
                          Page_Number: page,
                      };

            const response = await Casino_APIs.Get_Qtech_Casino_Games(
                payload,
                userToken || User.token
            );
            const { data, success } = response.data ? response.data : {};
            if (success) {
                setLoading(false);
                const finalDataList = decryptData(data);
                if (finalDataList?.payload?.games) {
                    setGameList(finalDataList.payload.games);
                }
                setPageNo(2);
            }
        } catch (error) {
            console.log("==========>error", error);
            setLoading(false);
            if (pageNo === 0) {
                setGameList([]);
            }
            if (error.response && error.response.status === 401) {
                if (
                    location?.pathname === "/casino-category-mobile" &&
                    window?.ReactNativeWebView
                ) {
                    toast.error("Please login again");
                    window.ReactNativeWebView.postMessage(JSON.stringify({ error: 401 }));
                } else {
                    Logout_User(Set_User);
                    navigate("/");
                }
            }
        }
    };

    const getNextListOfGames = async () => {
        setNextListLoading(true);
        try {
            let payload =
                activeCasinoProvider === "QTECH"
                    ? {
                          pageNo: pageNo,
                          itemPerPage: itemsPerPage,
                          category: selectedCategory,
                      }
                    : {
                          Category_ID: categoriesId,
                          Items_per_Page: itemsPerPage,
                          Page_Number: pageNo,
                      };
            const response = await Casino_APIs.Get_Qtech_Casino_Games(
                payload,
                userToken || User.token
            );
            const { data, success } = response.data ? response.data : {};
            if (success) {
                const finalDataList = decryptData(data);
                if (finalDataList.payload.games.length > 0) {
                    setNextListLoading(false);
                    setGameList([...gameList, ...finalDataList.payload.games]);
                    setPageNo(pageNo + 1);
                } else {
                    setNextListLoading(false);
                }
                setNextListLoading(false);
            }
        } catch (error) {
            setNextListLoading(false);
            setLoading(false);
            if (pageNo === 0) {
                setGameList([]);
            }
            if (error.response && error.response.status === 401) {
                if (
                    location?.pathname === "/casino-category-mobile" &&
                    window?.ReactNativeWebView
                ) {
                    toast.error("Please login again");
                    window.ReactNativeWebView.postMessage(JSON.stringify({ error: 401 }));
                } else {
                    Logout_User(Set_User);
                    navigate("/");
                }
            }
        }
        setNextListLoading(false);
    };

    useEffect(() => {
        if (isBottom) {
            getNextListOfGames();
        }
    }, [isBottom]);

    useEffect(() => {
        if (location?.pathname === "/casino-category-mobile") {
            updateUserContext();
        } else {
            getCategories();
        }
        getAppConfig();
    }, []);

    const onClickHandler = (item) => {
        setSelctedCategory(item ? item : "SLOT");
    };

    useEffect(() => {
        getGames(1, selectedCategory);
    }, [selectedCategory]);

    const onClickWacHandler = (item) => {
        setCategoriesId(item._id);
        getGames(1, item?._id ?? "");
    };

    return (
        <>
            {loading && <Loader />}
            <div>
                {!loading && (
                    <div
                        className={
                            location.pathname === "/casino-category-mobile"
                                ? ""
                                : "header-background-holder"
                        }
                    >
                        <div className="header-background withBanner">
                            <img
                                className={isTabOrMobile ? "bgcasino-mobile" : "bgcasino"}
                                src={BgImg}
                            ></img>
                        </div>
                    </div>
                )}

                <div
                    className={
                        isTabOrMobile
                            ? "casino-category-categories-list"
                            : "casino-category-categories-list-desktop"
                    }
                >
                    {!loading &&
                        categories.map((item, index) => {
                            return activeCasinoProvider === "QTECH" ? (
                                <div
                                    key={index}
                                    className="col-5 casnTb"
                                    onClick={() => onClickHandler(item.category ?? "SLOT")}
                                >
                                    {item.category === "VIRTUAL_SPORTS" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={TokenIcon}
                                        ></img>
                                    )}
                                    {item.category === "VIDEOPOKER" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={VideoVideoPokerIcon}
                                        ></img>
                                    )}
                                    {item.category === "LIVECASINO" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={LiveCasinoIcon}
                                        ></img>
                                    )}
                                    {item.category === null && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== "SLOT"
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={SlotsIcon}
                                        ></img>
                                    )}
                                    {item.category === "INSTANTWIN" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={InstantWinIcon}
                                        ></img>
                                    )}
                                    {item.category === "TABLEGAME" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={VideoPokerIcon}
                                        ></img>
                                    )}
                                    {item.category === "LOTTERY" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={LotteryIcon}
                                        ></img>
                                    )}
                                    {item.category === "SHOOTING" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={ShootingIcon}
                                        ></img>
                                    )}
                                    {item.category === "SCRATCHCARD" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                selectedCategory !== item.category
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={VirtualSportIcon}
                                        ></img>
                                    )}
                                    <span>{item.category ? item.category : "SLOT"}</span>
                                </div>
                            ) : (
                                <div
                                    key={index}
                                    className="col-5 casnTb"
                                    onClick={() => onClickWacHandler(item)}
                                >
                                    {item.Name === "Roulette" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Roulette}
                                        ></img>
                                    )}
                                    {item.Name === "Live Casino" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Livecasino}
                                        ></img>
                                    )}
                                    {item.Name === "Blackjack" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Blackjack}
                                        ></img>
                                    )}
                                    {item.Name === "SLOT" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={SlotsIcon}
                                        ></img>
                                    )}
                                    {item.Name === "Baccarat" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Baccarat}
                                        ></img>
                                    )}
                                    {item.Name === "Dragon Tiger" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={VideoPokerIcon}
                                        ></img>
                                    )}
                                    {item.Name === "Poker" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Poker}
                                        ></img>
                                    )}
                                    {item.Name === "Ezugi" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Ezugi}
                                        ></img>
                                    )}
                                    {item.Name === "Evolution" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Evolution}
                                        ></img>
                                    )}
                                    {item.Name === "Virtuals" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Virtuals}
                                        ></img>
                                    )}
                                    {item.Name === "Andar Bahar" && (
                                        <img
                                            className={`casino-category-icon-image ${
                                                categoriesId !== item._id
                                                    ? "casino-cateogry-icon-selected-image"
                                                    : ""
                                            }`}
                                            src={Andarbahar}
                                        ></img>
                                    )}
                                    <span>{item.Name}</span>
                                </div>
                            );
                        })}
                </div>

                {/* <div
                    className="scrolling-wrapper-casio"
                >
                    <div className='wrapper-inner'>


                        <div className="col-5 casnTb">
                            <img src={LiveCasinoIcon}></img>
                            <span>My Casino</span>
                        </div>
                        <div onClick={() => onClickHandler("Andar Bahar")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Andar Bahar</span>
                        </div>
                        <div onClick={() => onClickHandler("Baccarat")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Baccarat</span>
                        </div>
                        <div onClick={() => onClickHandler("Lucky 7")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Lucky 7</span>
                        </div>
                        <div onClick={() => onClickHandler("Dragon Tiger")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Dragon Tiger</span>
                        </div>
                        <div onClick={() => onClickHandler("Poker")} className="col-5 casnTb">
                            <img src={VideoPokerIcon}></img>
                            <span>Poker</span>
                        </div>
                        <div onClick={() => onClickHandler("Roulette")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Roulette</span>
                        </div>
                        <div onClick={() => onClickHandler("BlackJack")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Black Jack</span>
                        </div>
                        <div onClick={() => onClickHandler("Teen Patti")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Teen Patti</span>
                        </div>
                        <div onClick={() => onClickHandler("SLOT")} className="col-5 casnTb">
                            <img src={SlotsIcon}></img>
                            <span>Slots</span>
                        </div>
                        <div onClick={() => onClickHandler("Virtual")} className="col-5 casnTb">
                            <img src={VirtualSportIcon}></img>
                            <span>Virtuals</span>
                        </div>
                    </div>
                </div> */}

                {/* <div className='mt-2'>
                    <div className='sorting'>
                        <span>SORT BY</span>
                    </div>
                    <div className='btn-sort'>
                        <div>
                            <Button className='btnsrt'>
                                <Name className='btn-svg' />
                                Name
                            </Button>
                        </div>
                    </div>
                </div> */}

                {!loading && (
                    <div
                        className={
                            isTabOrMobile
                                ? "providers-top-games-container"
                                : "providers-top-games-container mx-3"
                        }
                    >
                        <TopGamesSlider />
                    </div>
                )}

                {!loading && (
                    <div
                        className={
                            isTabOrMobile
                                ? "providers-top-games-container mb-3"
                                : "providers-top-games-container mb-3 mx-3"
                        }
                    >
                        <TopProvidersSlider />
                    </div>
                )}

                {activeCasinoProvider === "QTECH" ? (
                    <div
                        className={
                            isTabOrMobile
                                ? "row md-rwo tps colsp crvimgrst indTp mb-3 mt-4 mx-3"
                                : "row md-rwo tps colsp crvimgrst indTp mb-3 mt-4"
                        }
                    >
                        {!loading &&
                            gameList.map((data) => {
                                if (data.images[2]) {
                                    const handleOnRedirect = () => {
                                        if (location.pathname === "/casino-category-mobile") {
                                            navigate("/qtech-casino-game-mobile", {
                                                state: { gameId: data.gameId },
                                            });
                                            return;
                                        }
                                        navigate("/qtech-casino-game", {
                                            state: { gameId: data.gameId },
                                        });
                                    };

                                    return (
                                        <div className="col-6 col-md-2 gamelist">
                                            <div
                                                className="gamelist-img"
                                                onClick={handleOnRedirect}
                                            >
                                                <img
                                                    alt={data.Name}
                                                    className="img-fluid"
                                                    src={
                                                        data.images[2]
                                                            ? data.images[2].url
                                                            : Thumbnail_Fallback_Image
                                                    }
                                                    loading="lazy"
                                                ></img>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                ) : (
                    <div
                        className={
                            isTabOrMobile
                                ? "row md-rwo tps colsp crvimgrst indTp mb-3 mt-4 mx-3"
                                : "row md-rwo tps colsp crvimgrst indTp mb-3 mt-4"
                        }
                    >
                        {gameList.map((data) => {
                            const routeParams = {
                                state: {
                                    Game_URI: `${WACS_Endpoint}?token=${User?.data?._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${data.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
                                },
                            };
                            const handleOnRedirect = () => {
                                if (location.pathname === "/casino-category-mobile") {
                                    navigate("/casino-game-mobile", routeParams);
                                    return;
                                }
                                navigate("/casino-game", routeParams);
                            };
                            return (
                                <div className={"col-6 col-md-2 crv-csn"} key={data.Game_Code}>
                                    <div className="member-img" onClick={handleOnRedirect}>
                                        <img
                                            alt={data.Name}
                                            className="carousel-image-qtech"
                                            src={
                                                data.Thumbnail
                                                    ? data.Thumbnail
                                                    : Thumbnail_Fallback_Image
                                            }
                                            loading="lazy"
                                        ></img>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
                {nextListLoading && <Mini_Loader />}
            </div>
        </>
    );
}

export default CasinoCategories;
