import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./MostPlayed.css";
// import assets

// Assets
import Thumbnail_Fallback_Image from "../../Assets/Images/Thumbnail_Fallback_Image.webp";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { User_Context } from "../../Contexts/User";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";
import { useNavigate } from "react-router-dom";
import { SessionHelper } from "../../Utilities/SessionHelper";

const MostPlayed = ({ data }) => {
    const [providerBanners, setProviderBanners] = useState([]);
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const navigate = useNavigate();
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const { User } = useContext(User_Context);

    return (
        <div className="most-played-section">
            <GamesHeaderMarker showBtn={false} markerText={"Most Played"} />
            <div className="swiper-container">
                <Swiper
                    id="provider-swiper"
                    slidesPerView={3}
                    slidesPerColumn={2}
                    className="most-played-swipper-container"
                    freeMode={true}
                    loop={false}
                    autoplay={{ delay: 2500 }}
                    slidesPerGroup={3}
                    navigation={false}
                    pagination={{ clickable: true }}
                    modules={[FreeMode, Navigation, Pagination, Autoplay]}
                >
                    {data?.map((item, index) => {
                        if ((index + 1) % 2 === 0) return null;

                        return (
                            <SwiperSlide
                                key={index}
                                style={{ width: "220px", backgroundColor: "transparent" }}
                            >
                                <div
                                    className="most-played-game-lobby-row"
                                    style={{ marginTop: "4px" }}
                                    onClick={
                                        activeCasinoProvider === "QTECH"
                                            ? () =>
                                                  navigate("/qtech-casino-game", {
                                                      state: {
                                                          gameId: data[index].gameId,
                                                          tableId: data[index].tableId,
                                                      },
                                                  })
                                            : () =>
                                                  navigate("/casino-game", {
                                                      state: {
                                                          Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${item.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
                                                      },
                                                  })
                                    }
                                >
                                    <LazyLoadImage
                                        className="most-played-game-image"
                                        src={item?.images?.[1]?.url || Thumbnail_Fallback_Image}
                                        alt="Fairbets"
                                    />
                                </div>
                                {data[index + 1] && (
                                    <div
                                        className="most-played-game-lobby-row"
                                        style={{ marginTop: "4px" }}
                                        onClick={
                                            activeCasinoProvider === "QTECH"
                                                ? () =>
                                                      navigate("/qtech-casino-game", {
                                                          state: {
                                                              gameId: data[index + 1].gameId,
                                                              tableId: data[index + 1].tableId,
                                                          },
                                                      })
                                                : () =>
                                                      navigate("/casino-game", {
                                                          state: {
                                                              Game_URI: `${WACS_Endpoint}?token=${
                                                                  User.data._id
                                                              }&pn=${
                                                                  process.env.REACT_APP_PN
                                                              }&lang=en&game=${
                                                                  data[index + 1].Game_Code
                                                              }&type=${WACS_Payment_Modes.Charged}`,
                                                          },
                                                      })
                                        }
                                    >
                                        <LazyLoadImage
                                            className="most-played-game-image"
                                            src={
                                                data[index + 1]?.images?.[1]?.url ||
                                                Thumbnail_Fallback_Image
                                            }
                                            alt="Fairbets"
                                        />
                                    </div>
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default MostPlayed;
