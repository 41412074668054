import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

// Components
import Login from "../../components/Modals/Login";
import SideNavigation from "../../components/SideNavigation/SideNavigation";
import LanguagePopup from "../../components/Popups/LanguagePopup";
import ComponentHeading from "../../components/ComponentHeading/ComponentHeading";
import { Translation_Table } from "../../Configuration/Translation";
import MainBanner from "../../components/Banner/MainBanner";
import GameSlider from "../../components/GameSlider/GameSlider";
import QTechGameCard from "../../components/QTechGameCard/QTechGameCard";
import GameLobbyBanners from "../../components/GameLobbyBanners/GameLobbyBanners";
import GamePageBanners from "../../components/GamePageBanners/GamePageBanners";
import NewExchange from "../../components/Exchange/NewExchange";

// Context
import { Game_Context } from "../../Contexts/Games";
import { Language_Context } from "../../Contexts/Language";
import { User_Context } from "../../Contexts/User";

// Styles
import "./Home.css";
import "../../index.css";
import "../../Style/Utility_Classes.css";
import GameSattaMarket from "../../components/GameSattaMarket/GameSattaMarket";
import IndianGames from "../IndianGames/IndianGames";
import HighEarningGames from "../HighEarningGames/HighEarningGames";
import { Various_APIs } from "../../API/Various_APIs";
import { SessionHelper } from "../../Utilities/SessionHelper";
import { Providers_Enums } from "../../Configuration/Enums/Providers";
import { RegisterPopup } from "../../components/RegisterPopup/RegisterPopup";
import Game_Card from "../../components/Game_Card/Game_Card";

import TopProviders from "../../components/TopProviders/TopProviders";
import TopGames from "../../components/TopGames/TopGames";
import MostPlayed from "../../components/MostPlayed/MostPlayed";
import PlayGame from "../../components/PlayGame/PlayGame";
import TopSattaMarket from "../../components/TopSattaMarket/TopSattaMarket";
import ExchangeBar from "../../components/ExchangeBar/ExchangeBar";

const Home = () => {
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const { User, Set_User } = useContext(User_Context);

    const navigate = useNavigate();
    const [showLanguagePopup, setShowLanguagePopup] = useState(false);
    const [Modal_Visibility_Status, Set_Modal_Visibility] = useState(false);
    const Location = useLocation();
    const Cookie = new Cookies();
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const navigateFun = (url) => {
        navigate(`/${url}`, {
            state: {
                Category: new URLSearchParams(window.location.search).get("data") || "",
            },
        });
    };

    const getAppConfig = async () => {
        const appConfig = await Various_APIs.Get_App_Config();
        SessionHelper.setActiveExchangeProvider(
            appConfig?.data?.data?.payload?.activeCasinoProvider ?? Providers_Enums.CAPITAL_QTECH
        );
    };

    const checkIfFirstTimeUser = () => {
        const isFirstTime = Cookie.get("isFirstTimeUser");
        if (isFirstTime !== "true" && (User.token == "" || !User?.token)) {
            setShowRegisterModal(true);
        }
    };

    useEffect(() => {
        Set_Modal_Visibility(
            Location.state &&
                Location.state.Authentication_Status &&
                Location.state.Authentication_Status
        );
        checkIfFirstTimeUser();
        getAppConfig();
    }, []);

    const handleHideRegisterPopup = () => {
        Cookie.set("isFirstTimeUser", true);
        setShowRegisterModal(false);
    };

    const onRegisterPopupPress = () => {
        setShowRegisterModal(false);
        Cookie.set("isFirstTimeUser", true);
        navigate("/user-register");
    };

    useEffect(() => {
        if (param === "feedback") {
            // notify();
            navigate("/");
        }
    }, [0]);
    // const notify = () => {
    //   toast.success("Feedback was successfully submitted");
    // };
    const param = new URLSearchParams(Location.search).get("data");

    const [progress, setProgress] = useState(20);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const [showSideNav, setShowSideNav] = useState(false);
    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const {
        topGames,
        // indianGames,
        mostPlayed,
        bannerArray,
        gameArray,
        whatsapp,
        telegram,
        instagram,
        twitter,
        bannerImage,
    } = gameState;

    const { Language, Set_Language } = useContext(Language_Context);

    const Change_Language = (Language_Code) => {
        Set_Language(Language_Code);
        document.documentElement.lang = Language_Code;
        Cookie.set("language", Language_Code);
    };

    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    useEffect(() => {
        console.log(topGames);
    }, []);
    return (
        <>
            <div
                className="home-outer-container"
                style={{ marginLeft: showSideNav ? "14rem" : "" }}
            >
                <div className="home-container">
                    {!isTabOrMobile && (
                        <div className="wd-20 mt-2">
                            <SideNavigation
                                Language={Language}
                                setShowLanguagePopup={setShowLanguagePopup}
                                twitter={twitter}
                                whatsapp={whatsapp}
                                telegram={telegram}
                                instagram={instagram}
                            />
                        </div>
                    )}
                    <div className={isTabOrMobile ? "wd-100" : "wd-80"}>
                        <div className="imgOuter">
                            <MainBanner data={bannerArray} navigateTo={navigateFun} />
                        </div>
                        <ExchangeBar />
                        <TopProviders />
                        <MostPlayed data={mostPlayed} />
                        <PlayGame
                            data={gameArray}
                            navigateFun={navigateFun}
                            heading={Translation_Table.playGame[Language]}
                        />
                        {/* <NewExchange /> */}
                        <TopSattaMarket />
                        <TopGames data={topGames} />
                        <GamePageBanners />
                        <IndianGames />
                        <HighEarningGames />
                    </div>
                </div>
            </div>

            {showLanguagePopup && (
                <LanguagePopup
                    setShowLanguagePopup={setShowLanguagePopup}
                    Language={Language}
                    Change_Language={Change_Language}
                />
            )}

            <Login
                show={Modal_Visibility_Status}
                onHide={() => Set_Modal_Visibility(false)}
                close={() => Set_Modal_Visibility(false)}
            />
            <RegisterPopup
                isVisible={showRegisterModal}
                setIsVisible={handleHideRegisterPopup}
                onRegisterPress={onRegisterPopupPress}
            />
        </>
    );
};

export default Home;
