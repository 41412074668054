import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";
import Eyeslash from "../../images/eye-slash.svg";
import Eyefill from "../../images/eye-fill.svg";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";

// Components
import GeneralTextfield from "../Textfields/GeneralTextfield";
import GeneralButton from "../Buttons/GeneralButton";

import "../../css/changepassword.css"

function ChangePasswordModal(props) {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible1, setPasswordVisible1] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [validateConfirmPassword, setValidateConfirmPassword] = useState(false);
    const [validateNewPassword, setValidateNewPassword] = useState(false);
    const [validateOldPassword, setValidateOldPassword] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const navigate = useNavigate()
    const { User, Set_User } = useContext(User_Context);
    const { Language, Set_Language } = useContext(Language_Context)


    const validatePassword = (password) => {
        const emailRegex = /(?=.{8,})/;
        return emailRegex.test(password);
    };
    const notify = () => {
        toast.success("Password was successfully updated");
    };

    const handleToggle = () => {
        setPasswordVisible(!passwordVisible);
    };
    const handleToggle1 = () => {
        setPasswordVisible1(!passwordVisible1);
    };
    const handleToggle2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
    const changePassword = async () => {
        setOldPasswordError(false);
        if (newPassword !== newConfirmPassword) {
            setValidateConfirmPassword(false);
        } else {
            setValidateConfirmPassword(true);
        }
        setValidateOldPassword(validatePassword(oldPassword));
        setValidateNewPassword(validatePassword(newPassword));
        if (validateOldPassword && validateConfirmPassword && validateNewPassword) {
            const data =
            {
                userId: User.data._id,
                password: oldPassword,
                newPassword: newPassword,
            };
            User_APIs.Update_Password(data, User.token)
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        notify();
                        props.onHide();
                    }
                })
                .catch((error) => {
                    // setOldPasswordError(true);
                    if (error.response.status === 403) {
                        setOldPasswordError(true);
                        toast.error('Your old Password is wrong')
                    }
                    if (error.response.status === 401) {
                        Logout_User(Set_User)
                        navigate("/")
                    }
                });
        }
    };
    return (
        <Modal
            {...props}
            //show={modalChangePassword}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="kyc-hdr">
                {Translation_Table.changePassword[Language]}
                <Crosssmall
                    onClick={() => {
                        setValidateOldPassword(true);
                        props.onHide();
                    }}
                    className="cross-kyc"
                />
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="container login">
                        <div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="username-input mt-3">

                                            {validateOldPassword && !oldPasswordError ? (
                                                <GeneralTextfield
                                                    onChange={(e) => { setOldPassword(e.target.value) }}
                                                    label={Translation_Table.oldpassword[Language] + " * "}
                                                    type={passwordVisible ? "text" : "password"}
                                                    autoComplete={false}
                                                />


                                            ) : (
                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        setValidateOldPassword(
                                                            validatePassword(e.target.value)
                                                        );
                                                        setOldPassword(e.target.value);
                                                        setOldPasswordError(false)
                                                    }}
                                                    error={true}
                                                    label={
                                                        oldPasswordError && !validatePassword
                                                            ? Translation_Table.entermin8characterpassword[Language]
                                                            : oldPasswordError
                                                                ? Translation_Table.oldpasswordnotmatched[Language]
                                                                : !validatePassword(oldPassword)
                                                                    ? Translation_Table.entermin8characterpassword[Language]
                                                                    : ""
                                                    }
                                                    type={passwordVisible ? "text" : "password"}
                                                />
                                            )}


                                            <div className="clrBoth" />
                                            <div className="eye1" onClick={handleToggle}>
                                                {passwordVisible ? (
                                                    <img src={Eyefill} />
                                                ) : (
                                                    <img src={Eyeslash} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="username-input ">
                                            {validateNewPassword ? (

                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        setNewPassword(e.target.value);
                                                    }}
                                                    label={

                                                        Translation_Table.newpassword[Language] + " * "
                                                    }
                                                    type={passwordVisible1 ? "text" : "password"}
                                                />

                                            ) : (

                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        setValidateConfirmPassword(
                                                            validatePassword(e.target.value)
                                                        );
                                                        setNewPassword(e.target.value);
                                                    }}
                                                    label={Translation_Table.entermin8characterpassword[Language]}
                                                    type={passwordVisible1 ? "text" : "password"}
                                                />

                                            )}
                                        </div>
                                        <div className="clrBoth" />
                                        <div className="eye1" onClick={handleToggle1}>
                                            {passwordVisible1 ? (
                                                <img src={Eyefill} />
                                            ) : (
                                                <img src={Eyeslash} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="username-input ">
                                            {validateConfirmPassword ? (

                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        setNewConfirmPassword(e.target.value);
                                                    }}
                                                    label={Translation_Table.confirmPassword[Language] + " * "}
                                                    type={passwordVisible2 ? "text" : "password"}
                                                />


                                            ) : (
                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        setNewConfirmPassword(e.target.value);
                                                    }}
                                                    label={Translation_Table.confirmpasswordnotmatched[Language]}
                                                    type={passwordVisible2 ? "text" : "password"}
                                                />

                                            )}
                                        </div>
                                        <div className="clrBoth" />
                                        <div className="eye1" onClick={handleToggle2}>
                                            {passwordVisible2 ? (
                                                <img src={Eyefill} />
                                            ) : (
                                                <img src={Eyeslash} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {newPassword !== newConfirmPassword ? <p className="passmatch">New Password and Confirm Password Does Not Match</p> : ""}
                            <div className="my-2">
                                <GeneralButton
                                    onClick={() => {
                                        changePassword();
                                    }}
                                    showText={true}
                                    text={Translation_Table.submit[Language]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ChangePasswordModal;
