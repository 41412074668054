import { API_Handler } from "./API_Handler";
import { encryptData } from "../Utilities/EncryptData";

export const User_APIs =
{
    Check_User_Existence: async (Request) => await API_Handler.post('/User/checkUser', { token: encryptData(Request) }),
    Register_User: async (Request) => await API_Handler.post('/User/register', { token: encryptData(Request) }),
    Log_In: async (Request) => await API_Handler.post('/User/log-in', { token: encryptData(Request) }),
    Log_Out: async (Request, Token) => await API_Handler.post('/User/logout', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Verify_OTP: async (Request) => await API_Handler.post('/User/verify-otp', { token: encryptData(Request) }),
    Update_Password: async (Request, Token) => await API_Handler.post('/User/update-password', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Forget_Password: async (Request, Token) => await API_Handler.post('/User/forget-password', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Request_OTP_for_Forgotten_Password: async (Request) => await API_Handler.post('/User/getOtp', { token: encryptData(Request) }),
    Get_Bet_History: async (Request, Token) => await API_Handler.post('/User/bet-history', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_User_Balance: async (Request, Token) => await API_Handler.post('/User/getUserBalance', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_User: async (Request, Token) => await API_Handler.post('/User/getUser', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Validate_JWT: async (Request, Token) => await API_Handler.post('/token/check', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Support_Executive_List: async (Request, Token) => await API_Handler.post('/User/support-executive-dp-id', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Support_Executive_Firebase_Token: async (Request, Token) => await API_Handler.post('/User/support-executive-firebase-token', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Register_User_For_Chat: async (Request, Token) => await API_Handler.post('/User/register-user-for-chat-application', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
}