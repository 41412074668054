import React, { useContext, useState } from 'react'
import "./SessionRunCard.css"
import PlaceMatchBetCard from '../PlaceMatchBetCard/PlaceMatchBetCard';
import starIcon from "../../../../Assets/new-exchange/Exchange-sports-images/starIcon.png"
import { ExchangeContexts } from '../../../../Contexts/ExchangeContext';
import { createUniqueKey } from '../../../../Utilities/ExchangeUniqueKey';
const SessionRunCard = ({ match, gameId }) => {
  const [betData, setBetData] = useState({ isBack: Boolean, gameId: gameId, run: "" });

  // open place bet section on user click on the bet back box and lay box
  const usePlaceBetContext = useContext(ExchangeContexts);

  console.log(match)

  // Create a unique key for each match based on the relevant fields
 

  // open place bet section on user click on the bet back box and lay box
  const onBetPlace = (data) => {

    // Update the place bet data object
    setBetData({ ...data, gameId: gameId });

    const uniqueKey = createUniqueKey(match);
    // update the place bet data object
    setBetData({ ...data, [gameId]: gameId })

    // Toggle the active match place bet section using the unique key
    usePlaceBetContext.setActiveMatchPlaceBetSection(prevState => ({
      [uniqueKey]: !prevState[uniqueKey],
    }));
  };

  const uniqueKey = createUniqueKey(match);




  return <>
    <div className="session-run-card">

      {/* run Row ------------------------------ */}
      <div className="session-runes-row">
        <div className="session-runes-row-wrapper">

          <div className="session-run-task-name">
            <img className='starIcon' src={starIcon} alt="loading" />
            {match?.runnerName}</div>

          <div className="session-run-back-lay-boxes">
            <div className="session-run-back-box"
              style={{ backgroundColor: match?.nRate === 0 && match?.nRun === 0 ? "#aebade" : "#0f36ac" }}
              onClick={() => {

                if (match?.nRate !== 0) {
                 
                  onBetPlace({
                    match: match,
                    isBack: true,
                    run: match?.nRun,
                    gameId: gameId
                  });
                } 
              }}>
              <div className="run-session-run">{match?.nRate !== 0 ? match?.nRate : ""} </div>
              <div className="run-session-over">{match?.nRun !== 0 ? match?.nRun : ""}</div>
            </div>
            <div className="session-run-lay-box"
              style={{ backgroundColor: match?.yRate === 0 && match?.yRun === 0 ? "#f1c6c0" : "#e85d4a" }}

              onClick={() => {
                if (match?.yRate !== 0) {
                 
                  onBetPlace({
                    match: match,
                    isBack: false,
                    run: match?.yRun,
                    rate: match?.yRate,

                  });
                } 
              }}>
              <div className="run-session-run">{match?.yRate !== 0 ? match?.yRate : ""}</div>
              <div className="run-session-over">{match?.yRun !== 0 ? match?.yRun : ""}</div>
            </div>
          </div>
        </div>

        {
          usePlaceBetContext.activeMatchPlaceBetSection[uniqueKey] && <PlaceMatchBetCard betData={betData} openedMatchPlaceBetSection={uniqueKey} />
        }


      </div>
      {/* run row ---------------------------- */}



    </div>

  </>
}

export default SessionRunCard
