import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { Exchange_APIs } from '../API/Exchange_APIs';
import { toast } from 'react-toastify';
import { User_Context } from './User';
import Loader from "../components/Loader/Loader"
export const ExchangeContexts = createContext(null);

const ExchangeContext = ({ children }) => { // Correct destructuring here

    const [activeMatchPlaceBetSection, setActiveMatchPlaceBetSection] = useState({});
    const [isInplay, setIsInplay] = useState(false);
    const [openBets, setOpenBets] = useState([])
    const { User } = useContext(User_Context)
    const [loading,setLoading]=useState(false)

    const getOpenBets = (marketType) => {
            const API_RESPONSE = Exchange_APIs.GET_OPEN_BETS(User.token);
            // check api response
            setLoading(true);
            API_RESPONSE.then((response) => {
                setLoading(false)
                if (response.data.data) {
                    
                    console.log("open bets------------------------",response)
                    setOpenBets(response.data.data);
                } else {
                    setLoading(false)
                    toast.info("No any Open Bets")
                }
            }).catch((error) => {
                setLoading(false)
                toast.error(error.message)
            })
    }

 

    return <>
        
        <ExchangeContexts.Provider
            value={{ activeMatchPlaceBetSection, setActiveMatchPlaceBetSection, isInplay, setIsInplay, openBets, setOpenBets,getOpenBets }}>
            {children}
        </ExchangeContexts.Provider>
        </>
};

export default ExchangeContext;
