import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Bonus_Wallet_APIs } from "../../API/Bonus_Wallet_API";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { Game_Context } from "../../Contexts/Games";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { Box, Tooltip, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

// Components
import SideNavigation from "../SideNavigation/SideNavigation";
import LanguagePopup from "../Popups/LanguagePopup";
import GeneralButton from "../Buttons/GeneralButton";
import ProfileButton from "../Buttons/ProfileButton";
import BackButton from "../Buttons/BackButton";
import Login from "../Modals/Login";
import SignUp from "../Modals/SignUp";
import BonusWalletModal from "../Modals/BonusWalletModal";

// Assets
import { ReactComponent as Backward_Navigation_Icon } from "../../Assets/Icons/Backward_Navigation_Icon.svg";
import { ReactComponent as Menu_Icon } from "../../Assets/Icons/Menu.svg";
import { ReactComponent as Homeicon } from "../../Assets/footer-icons-new/Home.svg";
import { ReactComponent as Fairbets_Header_White } from "../../Assets/Icons/Fairbets_Header_White.svg";
import { ReactComponent as Filtersvg } from "../../images/svg/filter.svg";
import Exchangeicon from "../../images/home-footer/exchangedark.png";
import Casinoicon from "../../images/profile-images/live-casino-blue.png";
import Lotteryicon from "../../images/profile-images/Lottery-blue.png";
import Sattaicon from "../../images/profile-images/satta-matka-blue.png";
import Logouticon from "../../images/profile-images/logout-blue.png";
import BonusIcon from "../../Assets/headerIcons/bonusIcon.png";
import DepositIcon from "../../Assets/headerIcons/depositIcon.png";
import { ReactComponent as MenuIcon } from "../../Assets/headerIcons/menuIcon.svg";
import profileIcon from "../../Assets/headerIcons/profileIcon.png";
import { ReactComponent as RuppeeIcon } from "../../Assets/headerIcons/ruppeeIcon.svg";
// Styles
import "./Header.css";
import "../../css/header1.css";
import "../../css/payment.css";
import "../../css/profileheader.css";
import "../../css/language.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Kyc from "../Modals/Kyc";

function NewHeader({
    login,
    isProfile,
    isGame,
    isDeposit,
    isDepositMobile,
    isFeedback,
    isBetHistory,
    isBonusWallet,
    onBetHistoryClick,
    showBetHistoryFilter,
    isAccountStatement,
    onAccountStatementClick,
    showAccountStatementFilter,
    isBonusScreen,
    isBonusMobileScreen,
    isWithdrawalRequest,
    isCasinoCategoryMobile,
    isMobileScanner,
    isQtechCasinoGameMobile,
    lat,
    lan,
}) {
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
    const isDesktop = window.matchMedia("(max-width: 1100px)").matches;

    const location = useLocation();

    const [modalShowLogin, setModalShowLogin] = useState(false);
    const [modalShowSignUp, setModalShowSignUp] = useState(false);
    const [modalShowBonusWallet, setModalShowBonusWallet] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { Language, Set_Language } = useContext(Language_Context);
    const [selectedLanguage, setSelectedLanguage] = useState(Language_Context);
    const [showLanguagePopup, setShowLanguagePopup] = useState(false);
    const componentReference = useRef(null);
    const { User, Set_User } = useContext(User_Context);
    const [PWA_Installation_Status, Set_PWA_Installation_Status] = useState(false);
    const [PWA_Installation_Prompt, Set_PWA_Installation_Prompt] = useState(null);
    const [kycModal, setKycModal] = useState(false);

    console.log(User.data);

    /* PWA Installation Handler*/

    window.addEventListener("beforeinstallprompt", (Event_Object) => {
        Event_Object.preventDefault();
        Set_PWA_Installation_Prompt(Event_Object);
    });

    window.addEventListener("appinstalled", () => Set_PWA_Installation_Prompt(null));

    const Install_PWA = async () => {
        PWA_Installation_Prompt.prompt();
        const { outcome } = await PWA_Installation_Prompt.userChoice;
        if (outcome === "accepted") {
            Set_PWA_Installation_Status(true);
        } else if (outcome === "dismissed") {
            Set_PWA_Installation_Status(false);
        }
    };

    useEffect(() => {
        Set_PWA_Installation_Status(PWA_Installation_Prompt === null);
    }, [PWA_Installation_Prompt]);

    /* PWA Installation Handler*/

    const handleOutsideClick = (event) => {
        if (componentReference.current && !componentReference.current.contains(event.target)) {
            closeSideMenu();
            setShowMenu(false);
            setIsPopupVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    const navigate = useNavigate();

    const showSideMenu = () => {
        setShowMenu(true);
    };

    const closeSideMenu = () => {
        setShowMenu(false);
    };

    const navigateToDeposit = () => {
        if (!lat && !lan) {
            toast.error("please allow location access");
        } else if (!User.data.kyc) {
            setKycModal(true);
            toast.error("please complete your kyc first");
           
        } else {
            navigate("/deposit?data=deposit", {
                state: { Previous_Pathname: window.location.pathname },
            });
        }
    };

    const navigateToBionus = () => {
        navigate("/bonus");
    };

    const bonusWalletTransferRequest = async () => {
        try {
            let payload = {
                userId: User.data?._id,
                // userId: '65c754f85c7797e43dd34cf8'
            };
            const response = await Bonus_Wallet_APIs.createTransferRequest(payload, User.token);
            const { data, success } = response.data ? response.data : {};
            if (success) {
                setModalShowBonusWallet(false);
                toast.success(`Transfer Requested Created!`);
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            } else {
                setModalShowBonusWallet(false);
                toast.error(`User not found, Please contact Customer Support`);
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            }
        } catch (error) {
            if (error && error.response && error.response.status === 400) {
                // Logout_User(Set_User)
                toast.error(error?.response?.data?.message);
                navigate("/");
            }
            if (error && error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
    };

    const handleRootClick = () => {
        if (isPopupVisible) {
            setIsPopupVisible(false);
        }
    };

    useEffect(() => {
        // This effect will run once when the component mounts and set the selected language
        // from the cookie if it exists.
        setSelectedLanguage(Language_Context);
    }, []);

    const navigateToProfile = () => {
        navigate("/profile");
    };

    const navigateToHome = () => {
        navigate("/");
    };

    const navigateToRegister = () => {
        navigate("/user-register");
    };

    const handleCopyClick = (value) => {
        navigator.clipboard.writeText(value);
    };
    useEffect(() => {
        setModalShowLogin(login);
    }, [login]);

    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const { whatsapp, telegram, instagram, twitter, appDetail } = gameState;

    if (isMobileScanner || isQtechCasinoGameMobile) return null;

    return (
        <header>
            {!isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isBonusWallet &&
                !isDeposit &&
                !isDepositMobile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div id="header" className="fixed-top" onClick={handleRootClick}>
                        {User.token === "" ? (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div className="header-container">
                                    <div className="header-logo-container">
                                        {isTabOrMobile && (
                                            <div className="side-menu-icon">
                                                <MenuIcon onClick={showSideMenu} />
                                            </div>
                                        )}

                                        <div onClick={navigateToHome} style={{ cursor: "pointer" }}>
                                            <Fairbets_Header_White className="header-logo" />
                                        </div>
                                    </div>

                                    <div
                                        className="header-button-container"
                                        style={{
                                            justifyContent: isTabOrMobile
                                                ? "flex-end"
                                                : "space-between",
                                        }}
                                    >
                                        {!isTabOrMobile ? (
                                            <a
                                                className="text-decoration-none"
                                                href={appDetail?.[0]?.path.toString()}
                                            >
                                                <GeneralButton
                                                    showIcon={false}
                                                    showText={true}
                                                    // showSmall={isTabOrMobile?true:false}
                                                    text={"Download App"}
                                                    // onClick={() => setModalShowSignUp(true)}
                                                    className={"header-button"}
                                                />
                                            </a>
                                        ) : null}
                                        <GeneralButton
                                            showIcon={true}
                                            showText={true}
                                            image={BonusIcon}
                                            text={Translation_Table.bonus[Language]}
                                            onClick={navigateToBionus}
                                            className={"header-button"}
                                        />

                                        <div
                                            className="login-btn"
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <GeneralButton
                                                showIcon={false}
                                                showText={true}
                                                colorFill={true}
                                                text={"Login"}
                                                onClick={() => setModalShowLogin(true)}
                                                className={"header-button"}
                                            />
                                            <GeneralButton
                                                showIcon={false}
                                                colorFill={true}
                                                showText={true}
                                                text={"Register"}
                                                // onClick={() => setModalShowSignUp(true)}
                                                onClick={navigateToRegister}
                                                className={"header-button"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        alignSelf: "flex-end",
                                    }}
                                >
                                    {isTabOrMobile ? (
                                        <a
                                            className="text-decoration-none"
                                            href={appDetail?.[0]?.path.toString()}
                                        >
                                            <GeneralButton
                                                showIcon={false}
                                                showText={true}
                                                // showSmall={isTabOrMobile?true:false}
                                                text={"Download App"}
                                                // onClick={() => setModalShowSignUp(true)}
                                                className={"header-button"}
                                            />
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div className="header-container">
                                    <div className="header-logo-container">
                                        {isTabOrMobile && (
                                            <div className="side-menu-icon">
                                                <MenuIcon onClick={showSideMenu} />
                                            </div>
                                        )}

                                        <div onClick={navigateToHome} style={{ cursor: "pointer" }}>
                                            <Fairbets_Header_White className="header-logo" />
                                        </div>
                                    </div>

                                    <div className="header-button-container">
                                        <GeneralButton
                                            showIcon={true}
                                            showText={true}
                                            image={BonusIcon}
                                            text={Translation_Table.bonus[Language]}
                                            onClick={navigateToBionus}
                                            className={"header-button"}
                                        />

                                        <GeneralButton
                                            showIcon={true}
                                            showText={true}
                                            image={DepositIcon}
                                            text={Translation_Table.deposit[Language]}
                                            onClick={navigateToDeposit}
                                            className={"header-button"}
                                        />
                                        {
                                            <div className="header-profile-icon-and-profile-details">
                                                <div className="profile-details">
                                                    <div className="profile-name">
                                                        {User.data.name}
                                                    </div>
                                                    <div className="balance">
                                                        <RuppeeIcon className="ruppee-icon" />
                                                        {parseFloat(User.data.balance.toFixed(2))}
                                                    </div>
                                                </div>
                                                <Link to={"/profile"}>
                                                    <LazyLoadImage
                                                        src={profileIcon}
                                                        className="profile-icon"
                                                    />
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            {isProfile &&
                !isGame &&
                !isFeedback &&
                !isDeposit &&
                !isDepositMobile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="profile-header-container">
                        <div className="profile-header-top">
                            <div
                                className="profile-header-text-container"
                                onClick={() => navigate("/")}
                            >
                                <Backward_Navigation_Icon />
                                <span className="user-name">My Account</span>
                            </div>
                        </div>

                        {User.data.referredCode && (
                            <div className="depo-mobile-copy-button pt-2 pb-1">
                                <span>{`Referred Code : ${
                                    User.data.referredCode ? User.data.referredCode : ""
                                }`}</span>
                                <Tooltip title="Copy" arrow>
                                    <IconButton
                                        className="back"
                                        size="small"
                                        onClick={() =>
                                            handleCopyClick(
                                                User.data.referredCode ? User.data.referredCode : ""
                                            )
                                        }
                                    >
                                        <FileCopyIcon
                                            style={{
                                                color: "white",
                                                width: "18px",
                                                height: "18px",
                                                marginInlineStart: "5px",
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )}

            {isFeedback &&
                !isGame &&
                !isProfile &&
                !isDeposit &&
                !isDepositMobile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="feedback-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "feedback-header-back-button"
                                    : "feedback-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "feedback-header-text"
                                    : "feedback-header-text-desktop"
                            }
                        >
                            <span className="user-name">
                                {Translation_Table.feedback[Language]}
                            </span>
                        </div>
                    </div>
                )}

            {isDeposit &&
                !isDepositMobile &&
                !isFeedback &&
                !isGame &&
                !isProfile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="feedback-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "feedback-header-back-button"
                                    : "feedback-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "feedback-header-text"
                                    : "feedback-header-text-desktop"
                            }
                        >
                            <span className="user-name">{Translation_Table.deposit[Language]}</span>
                        </div>
                    </div>
                )}
            {!isDeposit &&
                isDepositMobile &&
                !isFeedback &&
                !isGame &&
                !isProfile &&
                !isWithdrawalRequest &&
                !isAccountStatement &&
                !isCasinoCategoryMobile && (
                    <div className="feedback-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "feedback-header-text-mobile-deposit"
                                    : "feedback-header-text-desktop"
                            }
                        >
                            <span className="user-name">{Translation_Table.deposit[Language]}</span>
                        </div>
                    </div>
                )}

            {isAccountStatement &&
                !isDeposit &&
                !isDepositMobile &&
                !isFeedback &&
                !isGame &&
                !isProfile &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="bet-history-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-back-button"
                                    : "bet-history-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">
                                {Translation_Table.accountStatement[Language]}
                            </span>
                        </div>
                        {showAccountStatementFilter && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button"
                                        : "bet-history-header-filter-button-desktop"
                                }
                                onClick={onAccountStatementClick}
                            >
                                <Filtersvg className="filter-bet" />
                            </div>
                        )}
                    </div>
                )}

            {isGame &&
                !isProfile &&
                !isFeedback &&
                !isDeposit &&
                !isDepositMobile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="game-header" onClick={handleRootClick}>
                        <div
                            className={
                                isTabOrMobile
                                    ? "game-header-back-button"
                                    : "game-header-back-button-desktop"
                            }
                            onClick={() =>
                                location?.pathname === "/exchange" ? navigate("/") : navigate(-1)
                            }
                        >
                            <Backward_Navigation_Icon />
                        </div>

                        <div
                            className={
                                isDesktop
                                    ? "game-header-homepage-button"
                                    : "game-header-homepage-button-desktop"
                            }
                            onClick={() => navigate("/")}
                        >
                            <Homeicon width={22} height={22} />
                        </div>

                        <div
                            onClick={() => navigate("/")}
                            className={
                                isTabOrMobile
                                    ? "game-header-home-button"
                                    : "game-header-home-button-dekstop"
                            }
                        >
                            <Fairbets_Header_White
                                style={{ margin: "4px 0px 4px -45px", cursor: "pointer" }}
                                width="100px"
                            ></Fairbets_Header_White>
                        </div>
                        {isPopupVisible && (
                            <div className="popup-hdr">
                                <div className="pop">
                                    <div className="borderline">
                                        <img className="imgicon" src={Exchangeicon}></img>
                                        <a className="popup-a" href="/exchange">
                                            Exchange
                                        </a>
                                    </div>
                                    <div className="borderline">
                                        <img className="imgicon" src={Casinoicon}></img>
                                        <a className="popup-a" href="/casino">
                                            Casino
                                        </a>
                                    </div>
                                    <div className="borderline">
                                        <img className="imgicon" src={Lotteryicon}></img>
                                        <a className="popup-a" href="/lottrey">
                                            Lottery
                                        </a>
                                    </div>
                                    <div className="borderline">
                                        <img className="imgicon" src={Sattaicon}></img>
                                        <a className="popup-a" href="/satta">
                                            Satta
                                        </a>
                                    </div>
                                    <div>
                                        <img className="imgicon" src={Logouticon}></img>
                                        <a className="popup-a" href="/satta">
                                            Logout
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

            {!isProfile &&
                !isGame &&
                !isFeedback &&
                isBetHistory &&
                !isDeposit &&
                !isDepositMobile &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusWallet &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="bet-history-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-back-button"
                                    : "bet-history-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">{Translation_Table.mybets[Language]}</span>
                        </div>
                        {showBetHistoryFilter && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button"
                                        : "bet-history-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <Filtersvg className="filter-bet" />
                            </div>
                        )}
                    </div>
                )}

            {!isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isDeposit &&
                !isDepositMobile &&
                !isWithdrawalRequest &&
                !isAccountStatement &&
                isBonusWallet &&
                !isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="bet-history-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-back-button"
                                    : "bet-history-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">History</span>
                        </div>

                        {User.data.balance <= 100 &&
                            User.data.bonusWalletBalance > 0 &&
                            User.data.exposer === 0 && (
                                <div
                                    className={
                                        isTabOrMobile
                                            ? "bet-history-header-filter-button"
                                            : "bonus-wallet-header-filter-button-desktop"
                                    }
                                    onClick={onBetHistoryClick}
                                >
                                    <GeneralButton
                                        showText={true}
                                        text={"Request Transfer"}
                                        onClick={() => bonusWalletTransferRequest()}
                                    />
                                </div>
                            )}
                        {User.data.balance <= 100 &&
                            User.data.bonusWalletBalance > 0 &&
                            User.data.exposer > 0 && (
                                <div
                                    className={
                                        isTabOrMobile
                                            ? "bet-history-header-filter-button-balance"
                                            : "bonus-wallet-header-filter-button-desktop"
                                    }
                                    onClick={onBetHistoryClick}
                                >
                                    <span className="user-name" style={{ fontSize: 12 }}>
                                        {`Bonus Balance: ${User.data.bonusWalletBalance}`}
                                    </span>
                                </div>
                            )}
                        {(!User.data.bonusWalletBalance || User.data.bonusWalletBalance === 0) && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button-balance"
                                        : "bonus-wallet-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: "12px" }}>
                                    Balance: 0
                                </span>
                            </div>
                        )}
                        {User.data.balance > 100 && User.data.bonusWalletBalance > 0 && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button-balance"
                                        : "bonus-wallet-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: 12 }}>
                                    {`Bonus Balance: ${User.data.bonusWalletBalance}`}
                                </span>
                            </div>
                        )}
                    </div>
                )}

            {!isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isDeposit &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                isBonusScreen &&
                !isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="bonus-wallet-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-back-button"
                                    : "bet-history-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">Bonus Wallet</span>
                        </div>
                        {/* {showBetHistoryFilter &&
                        <div className={isTabOrMobile ? "bet-history-header-filter-button" : "bet-history-header-filter-button-desktop"} onClick={onBetHistoryClick}>
                            <Filtersvg className="filter-bet" />
                        </div>
                    } */}
                        {User.data.balance <= 100 &&
                            User.data.bonusWalletBalance > 0 &&
                            User.data.exposer === 0 && (
                                <div
                                    className={
                                        isTabOrMobile
                                            ? "bet-history-header-filter-button"
                                            : "bonus-wallet-header-filter-button-desktop"
                                    }
                                    onClick={onBetHistoryClick}
                                >
                                    <GeneralButton
                                        showText={true}
                                        text={"Request Transfer"}
                                        onClick={() => bonusWalletTransferRequest()}
                                    />
                                </div>
                            )}
                        {(!User.data.bonusWalletBalance || User.data.bonusWalletBalance === 0) && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bonus-screen-header-text-mob"
                                        : "bonus-screen-header-text-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: "15px" }}>
                                    Balance: 0
                                </span>
                            </div>
                        )}
                        {User.data.balance > 100 && User.data.bonusWalletBalance > 0 && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button-balance"
                                        : "bonus-wallet-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: 12 }}>
                                    {`Bonus Balance: ${User.data.bonusWalletBalance}`}
                                </span>
                            </div>
                        )}
                    </div>
                )}

            {!isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isDeposit &&
                !isAccountStatement &&
                !isWithdrawalRequest &&
                !isBonusScreen &&
                isBonusMobileScreen &&
                !isCasinoCategoryMobile && (
                    <div className="bonus-wallet-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">Bonus Wallet</span>
                        </div>
                        {/* {showBetHistoryFilter &&
                        <div className={isTabOrMobile ? "bet-history-header-filter-button" : "bet-history-header-filter-button-desktop"} onClick={onBetHistoryClick}>
                            <Filtersvg className="filter-bet" />
                        </div>
                    } */}
                        {User.data.balance <= 100 &&
                            User.data.bonusWalletBalance > 0 &&
                            User.data.exposer === 0 && (
                                <div
                                    className={
                                        isTabOrMobile
                                            ? "bet-history-header-filter-button"
                                            : "bonus-wallet-header-filter-button-desktop"
                                    }
                                    onClick={onBetHistoryClick}
                                >
                                    <GeneralButton
                                        showText={true}
                                        text={"Request Transfer"}
                                        onClick={() => bonusWalletTransferRequest()}
                                    />
                                </div>
                            )}
                        {(!User.data.bonusWalletBalance || User.data.bonusWalletBalance === 0) && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bonus-screen-header-text-mob"
                                        : "bonus-screen-header-text-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: "15px" }}>
                                    Balance: 0
                                </span>
                            </div>
                        )}
                        {User.data.balance > 100 && User.data.bonusWalletBalance > 0 && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button-balance"
                                        : "bonus-wallet-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: 12 }}>
                                    {`Bonus Balance: ${User.data.bonusWalletBalance}`}
                                </span>
                            </div>
                        )}
                    </div>
                )}

            {!isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isDeposit &&
                !isAccountStatement &&
                isWithdrawalRequest &&
                !isCasinoCategoryMobile && (
                    <div className="bonus-wallet-header">
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-back-button"
                                    : "bet-history-header-back-button-desktop"
                            }
                            onClick={() => navigate(-1)}
                        >
                            <Backward_Navigation_Icon />
                        </div>
                        <div
                            className={
                                isTabOrMobile
                                    ? "bet-history-header-text"
                                    : "bet-history-header-text-desktop"
                            }
                        >
                            <span className="user-name">Withdrawal Request</span>
                        </div>
                        {/* {showBetHistoryFilter &&
                        <div className={isTabOrMobile ? "bet-history-header-filter-button" : "bet-history-header-filter-button-desktop"} onClick={onBetHistoryClick}>
                            <Filtersvg className="filter-bet" />
                        </div>
                    } */}
                        {User.data.balance <= 100 &&
                            User.data.bonusWalletBalance > 0 &&
                            User.data.exposer === 0 && (
                                <div
                                    className={
                                        isTabOrMobile
                                            ? "bet-history-header-filter-button"
                                            : "bonus-wallet-header-filter-button-desktop"
                                    }
                                    onClick={onBetHistoryClick}
                                >
                                    <GeneralButton
                                        showText={true}
                                        text={"Request Transfer"}
                                        onClick={() => bonusWalletTransferRequest()}
                                    />
                                </div>
                            )}
                        {/* {(!User.data.bonusWalletBalance || User.data.bonusWalletBalance === 0) &&
                        <div className={isTabOrMobile ? "bonus-screen-header-text-mob" : "bonus-screen-header-text-desktop"} onClick={onBetHistoryClick}>
                            <span className="user-name" style={{ fontSize: '15px' }}>
                                Balance: 0
                            </span>
                        </div>
                    } */}
                        {User.data.balance > 100 && User.data.bonusWalletBalance > 0 && (
                            <div
                                className={
                                    isTabOrMobile
                                        ? "bet-history-header-filter-button-balance"
                                        : "bonus-wallet-header-filter-button-desktop"
                                }
                                onClick={onBetHistoryClick}
                            >
                                <span className="user-name" style={{ fontSize: 12 }}>
                                    {`Bonus Balance: ${User.data.bonusWalletBalance}`}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            {isCasinoCategoryMobile &&
                !isProfile &&
                !isGame &&
                !isFeedback &&
                !isBetHistory &&
                !isDeposit &&
                !isAccountStatement &&
                isWithdrawalRequest && <></>}

            {
                <div className={`side-drawer ${showMenu ? "side-drawer-open" : ""}`}>
                    <div className="ovl  nav-height ovl-1" id="menuHome" ref={componentReference}>
                        <div className=" col-sm-3  col-md-12  d-flex position-relative web-mnu">
                            <SideNavigation
                                Language={Language}
                                setShowLanguagePopup={setShowLanguagePopup}
                                twitter={twitter}
                                whatsapp={whatsapp}
                                telegram={telegram}
                                instagram={instagram}
                                isMobile={true}
                                closeSideMenu={closeSideMenu}
                            />
                        </div>
                    </div>
                </div>
                // )
            }

            {showLanguagePopup && (
                <LanguagePopup
                    setShowLanguagePopup={setShowLanguagePopup}
                    Language={Language}
                    Change_Language={Set_Language}
                />
            )}

            <Login
                show={modalShowLogin}
                onHide={() => {
                    setModalShowLogin(false);
                    navigate("/");
                }}
                close={() => {
                    navigate("/");
                    setModalShowLogin(false);
                }}
            />

            <SignUp
                show={modalShowSignUp}
                onHide={() => setModalShowSignUp(false)}
                whatsapp={whatsapp}
            />

            <BonusWalletModal
                show={modalShowBonusWallet}
                bonusWalletTransferRequest={bonusWalletTransferRequest}
                onHide={() => setModalShowBonusWallet(false)}
            />
            <Kyc
                closekyc={() => {
                    setKycModal(false);
                }}
                show={kycModal}
                onHide={() => {
                    setKycModal(false);
                }}
            />
        </header>
    );
}

export default NewHeader;
